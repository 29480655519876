import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKalina as author } from 'data/authors';
import img from 'img/blog/signup-improve.png';

import img1 from 'img/blog/unnamed.png';
import img2 from 'img/blog/screely-1579457606861.png';
import img3 from 'img/blog/unnamed-1-.png';
import img4 from 'img/blog/unnamed-2-.png';
import img5 from 'img/blog/unnamed-5-.png';
import img6 from 'img/blog/unnamed-4-.png';
import img7 from 'img/blog/unnamed-3-.png';
import img8 from 'img/blog/screely-1579465559231.png';
import img9 from 'img/blog/unnamed-6-.png';
import img10 from 'img/blog/wrapel1.png';

const Content = () => {
  return (
    <Post>
      <p>
        Sign-up forms are like medicines - you may hate them, but you know you have to take them to
        feel better. On websites, they play the role of “a ticket” to what is behind the main page -
        a product, a service or a special offer.
      </p>
      <h2 id="and-selling-that-ticket-may-be-more-troublesome-than-you-think-it-could-be-">
        And selling that ticket may be more troublesome than you think it could be.
      </h2>
      <p>
        Sign-up forms are a gate that your users need to cross to access your services. However, if
        that gate is off-putting and does not quite click, it can result in a high bounce rate and
        fewer conversions that you’d desire.
      </p>
      <p>
        The best sign-up form examples do not stand out with extraordinary features - rather than
        that, they combine the right practices in a customer-oriented form. Where to get some
        sign-up form inspiration and how to create a compelling form that will convert like crazy?
        Read this post to find out.
      </p>
      <aside>
        <p>
          <b>You’ll learn today:</b>
        </p>
        <ul>
          <li>what is a sign-up form</li>
          <li> some basic sign-up forms tips</li>
          <li>best practices for sign-up forms</li>
          <li>best sign-up form examples</li>
          <li>common mistakes with sign-up forms</li>
        </ul>
      </aside>

      <p>
        According to{' '}
        <a
          href="https://themanifest.com/web-design/6-steps-avoiding-online-form-abandonment"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          The Manifest
        </a>
        , 81% of people have abandoned a form after beginning to fill it out. 27% blame its length
        for this, but there are also many other reasons why sign-up forms do not convert. Before we
        start identifying what some sign-up form best practices are, let’s briefly explain what all
        the fuss is about.
      </p>
      <h2 id="what-is-a-sign-up-form-">
        What is a sign-up form<strong>?</strong>
      </h2>
      <p>
        Long story short: a sign-up form is a form requiring some information from a user who wants
        to take a particular action, such as a registration. For brands, a well-functioning form is
        needed to generate more leads and convert them into deals.
      </p>
      <h2 id="how-to-improve-a-sign-up-form-">How to improve a sign-up form?</h2>
      <p>
        There are quite a few methods you can use to improve your forms. Some of them can be
        included in a series of quick fixes, while implementing others can take a bit more time.
        Browse some sign-up form best practices along with top examples and get inspired.
      </p>
      <h3 id="make-your-sign-up-form-short-and-sweet">Make your sign-up form short and sweet</h3>
      <p>
        The most important thing about preparing a sign-up form is… not to overdo it. It’s
        understandable that you want to know a lot about your potential client, but the very first
        sign-up form they need to fill in is not the best time and place to do so.
      </p>
      <p>
        <em>How willing would you be to fill in the sign-up form below?</em>
      </p>
      <p>
        <img src={img1} alt="form" title="Sign-up form" />
      </p>
      <p>
        The length of the form is one thing - another is the amount of information and action it
        requires from the user. All of the fields are obligatory. In this case, the user needs to
        fill in eight fields if they want to download a single piece of content. That’s quite a lot
        of effort, isn’t it?
      </p>
      <p>
        Remember about the goal of your sign-up form - this is one of the very first touchpoints
        between your potential clients and your product.. There will be a better time and place to
        get to know your user better. At this point, the goal is to encourage them to register.
      </p>
      <h3 id="via-negativa-sign-up-form-creation">Via negativa sign-up form creation</h3>
      <p>
        Have you ever heard the term <em>via negativa</em>? It stands for “
        <em>addition by subtraction</em>”: in order to improve usability and deliver more added
        value, you actually remove existing elements instead of adding new ones.
      </p>
      <p>
        Take a look at this Basecamp example - they ask for a name and e-mail address. Would it hurt
        to ask for an e-mail only?
      </p>
      <p>
        <img src={img2} alt="form" title="Basecamp signup form" />
      </p>
      <p>
        The answer is: no. And Netflix knows about it, so they only for an e-mail address after
        describing their biggest asset: <strong>unlimited &amp; more, anytime, anywhere</strong>
        .They do not need to know a user’s name (yet) to serve them well, and the user can access
        the site in seconds. Doesn’t it sound like a great start?
      </p>
      <p>
        <img src={img3} alt="netlfix form" title="Netflix signup form" />
      </p>
      <h3 id="use-authority-quotes-and-benefits">Use authority quotes and benefits</h3>
      <p>
        As long as you should keep your sign-up forms concise and straight to the point, you also
        should think about implementing some incentives for those who may still be hesitant to
        sign-up. Adding some authority quotes and social proof (such as reviews from software review
        sites like Capterra, G2 or Trustpilot), as well as a brief list of benefits (sometimes a
        short sentence will do) can result in increased conversion rates.
      </p>
      <p>Take a look at a few examples.</p>
      <figure>
        <img src={img4} alt="form example" title="Example of authority in form - Filecamp" />
        <figcaption>
          <b>Filecamp.com</b> digital asset management software - used a testimonial to persuade
          those who still had doubts.
        </figcaption>
      </figure>

      <figure>
        <img src={img5} alt="form example" title="Example of authority in form - Liveagent" />
        <figcaption>
          <b>Liveagent.com</b>, customer support management software - listed their benefits in just
          two sentences and added social proof in the form of their client’s opinions – together
          with a picture of the client, to show that he’s a real person.
        </figcaption>
      </figure>

      <figure>
        <img src={img6} alt="form example" title="Example of authority in form - CloudTalk" />
        <figcaption>
          <b>CloudTalk.io</b>, call center management software. Instead of providing a single
          review, CloudTalk shows a couple of ratings from the most popular software review sites.
        </figcaption>
      </figure>

      <p>Oh, and we’re no different:</p>
      <figure>
        <img src={img7} alt="form example" title="Example of authority in form - CloudTalk" />
        <figcaption>
          At <b>LiveSession</b>, we provide solid social proof as well as a short list of benefits
          on our sign-up form landing page. You know exactly what kind of value you’re getting.
        </figcaption>
      </figure>

      <h3 id="make-it-quick-and-visible">Make it quick and visible</h3>
      <p>
        Let’s face it: your users don’t feel comfortable with providing too much data from the very
        beginning, especially nowadays. However, spending too much time on filling out the form is
        not something they’re keen on. Looking for the sign-up form forever isn’t their favorite
        activity either….
      </p>
      <p>
        You should let your potential clients sign up in seconds, and access the form from anywhere.
        The form itself is crucial, but if your users need to look for it for too long then they may
        give up and turn to your competitors. If you scroll Kontentino’s website (below), you’ll
        notice that a “sign up” button is scrollable and stays in the same place (top right). It
        doesn’t matter if you scroll the website up and down, so you can always sign up instantly,
        without seeking the magic button.
      </p>
      <p>
        <img src={img8} alt="form example" title="Form must be quick and visible" />
      </p>
      <p>
        Your sign-up form itself should be short and easy to implement. This way, you can attach it
        to blog posts or embed it in a pop-up without unnecessary hassle.
      </p>
      <p>
        Always give your potential customers one opportunity:{' '}
        <strong>to be a click away from signing up</strong>. You should also give them a chance to
        do it either by providing an email address or by logging in to Facebook or Google to shorten
        the customer journey. In other words, give them a choice.
      </p>
      <p>
        <img src={img9} alt="create account form" title="Simple form" />
      </p>
      <h3 id="sign-up-form-design-matters">Sign-up form design matters</h3>
      <p>
        On top of all of that, your sign-up form just has to be user-friendly and intuitive. Easier
        said than done? For sure, but it’s worth following sign-up form best practices to get
        inspired.
      </p>
      <p>
        Check out this example from{' '}
        <a href="https://www.typeform.com/" target="_blank" rel="noopener noreferrer nofollow">
          Typeform
        </a>
        . Minimalistic sign-up forms while presenting their USPs in a few words is not going to
        distract potential users.
      </p>
      <p>
        <img src={img10} alt="form" title="Signup form" />
      </p>
      <p>
        Finding the golden mean between great design,{' '}
        <a href="/blog/improving-customer-experience/" target="_blank" rel="noopener noreferrer">
          customer experience
        </a>{' '}
        and conversion is not the easiest task. This is why you should consider running some{' '}
        <a href="/blog/5-tips-for-better-a-b-tests/" target="_blank" rel="noopener noreferrer">
          A/B tests on your website
        </a>{' '}
        to see which solution works best in your case.
      </p>
      <p>
        You can also get a little creative and introduce a bit of humor. Writing something funny
        about passwords (for example: “It’ll be our secret” or “No worries, we won’t tell anyone”)
        can make you more memorable. Of course, the reception of these little jokes depends on your
        audience – why not test different approaches and see what works best in your case?
      </p>
      <h2 id="common-sign-up-forms-mistakes">Common sign-up forms mistakes</h2>
      <p>
        There are a few common mistakes that people often make with sign-up forms. Luckily, many are
        easily avoidable if you’re aware of them. Let us just list a few of the most popular ones:
      </p>
      <ul>
        <li>
          inserting text in the fields and forcing your users to delete the text first before they
          can write their data (how frustrating, no?)
        </li>
        <li>
          no clear info or goal of a sign-up - a potential user has no idea of what they register
          for
        </li>
        <li>requiring too much information on a sign-up form</li>
        <li>lack of building trust, both with a website and a sign-up form</li>
        <li>too many annoying pop-ups with a sign-up form</li>
        <li>poor copywriting, not communicating the product’s value</li>
      </ul>
      <aside>
        <h2>How to improve sign-up forms with LiveSession?</h2>
        <p>
          <b>LiveSession</b> can help you improve your sign-up forms. By{' '}
          <b>analyzing customer behavior</b> on your website, you’re able to recognize bottlenecks
          and potential problems your users encounter.
        </p>
        <p>You can identify what kind of copy and CTAs work for your potential clients.</p>
        <p>
          This kind of detailed qualitative analyics gives you <b>more context</b> for the numbers.
          Not only you will see what kind of sign-up forms work best but also why they work so well.
        </p>
        <p>
          What’s more, you can also see how your sign-up forms perform on different devices (desktop
          and mobile) and in different browsers. Thanks to{' '}
          <a
            href="/blog/how-to-use-filters-in-user-research/"
            target="_blank"
            rel="noopener noreferrer"
          >
            advanced segmentation features
          </a>
          , you can track the parameters that matter most.
        </p>
      </aside>

      <h2 id="to-wrap-up">To wrap-up</h2>
      <p>
        A sign-up form may seem like nothing but just a few fields that need to be filled – but it’s
        much more than that. It’s a chance to grab the user’s attention, make a great first
        impression and persuade them to make a commitment.
      </p>
      <p>
        Working on your sign-up form conversion can be tough and daunting but if you do it right, it
        leads to tremendous growth. Your clients are happy because they can register without too
        much effort, you are happy because you get more of them and your conversion rates skyrocket.
        A win-win situation, isn’t it?
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: '5 Tips to Improve Sign-up Forms (with examples)',
  url: '/blog/tips-to-improve-sign-up-forms-with-examples/',
  description: `Sign-up forms are like medicines - you may hate them, but you know you have to
  take them to feel better.`,
  author,
  img,
  imgSocial: img,
  date: '2020-01-24',
  category: '',
  group: 'Research',
  timeToRead: 7,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
